import React from 'react';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import connor from 'assets/team-members/connor.jpg';
import kyle from 'assets/team-members/kyle.jpg';
import ron from 'assets/team-members/ron.jpg';

const styles = {
  avatar: {
    width: 90,
    height: 90,
    '-webkit-box-shadow': '0px 5px 10px 0px rgba(0,0,0,0.15)',
    '-moz-box-shadow': '0px 5px 10px 0px rgba(0,0,0,0.15)',
    'box-shadow': '0px 5px 10px 0px rgba(0,0,0,0.15)',
    transition: 'all .15s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer'
    }
  },
  text: {
    paddingTop: 25
  }
};

const members = [
  {
    image: connor,
    url: 'https://github.com/cwybro'
  },
  {
    image: kyle,
    url: 'https://github.com/kwybro'
  },
  {
    image: ron,
    url: 'https://github.com/rwybro'
  }
];

class Header extends React.Component {

  handleClick = (url) => {
    window.open(url, '_blank', 'noopener');
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={1}>
        {members.map((m, index) => (
          <Grid item xs={12} sm={4} key={index} align="center">
            <Avatar
              alt="member image"
              src={m.image}
              className={classes.avatar}
              onClick={() => this.handleClick(m.url)}
            />
          </Grid>
        ))
        }
        <Grid item xs={12} className={classes.text}>
          <Typography variant="h2">
            We make things.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Header);
