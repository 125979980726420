import React from 'react';
import wybro from 'assets/wybro.svg';

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
};

const NavBar = ({ classes, featuredItems }) => (
  <AppBar position="static" className={classes.appBar}>
    <Toolbar>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <img src={wybro} width={50} height={50} className={classes.icon} alt='brand-icon' />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(NavBar);
