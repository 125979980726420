import React from 'react';
import NavBar from 'NavBar';
import Header from 'Header';
import glitch from 'assets/project-icons/glitch-icon.svg';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    height: 600
  },
  icon: {
    paddingLeft: 10,
    paddingTop: 10
  },
  root: {
    textAlign: 'center',
    maxWidth: 500,
    paddingTop: 150,
    margin: 'auto'
  },
  copyright: {
    color: '#8B8B8B',
    fontSize: 12
  },
  copyrightContainer: {
    paddingTop: 200,
    paddingBottom: 10
  },
  navbar: {
    paddingTop: 10
  }
};

const things = [
  {
    title: 'Glitch',
    image: glitch,
    url: 'https://getglitch.app'
  }
];

const App = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.navbar}>
      <NavBar featuredItems={things} />
    </div>
    <div className={classes.root}>
      <Header />
    </div>
    <Grid container className={classes.copyrightContainer} spacing={1}>
      <Grid item xs={12}>
        <Typography align='center' className={classes.copyright}>
          © Wybro LLC 2020. All Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(App);
